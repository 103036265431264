import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f9f1ea;

  & > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    & > .media {
      flex-basis: 48%;
    }

    & > .copy {
      flex-basis: 48%;
    }

    &.flip > .media {
      order: -1;
    }

    ${({ theme }) => theme.breakpoints.down("xs")} {
      text-align: center;
      ul {
        list-style: none;
      }

      & > .media {
        flex-basis: 100%;
        order: -1;
        margin-bottom: 2rem;
      }
      & > .copy {
        flex-basis: 100%;
      }
    }
  }

  ${props =>
    props.scrollSnap
      ? `
      padding-top: 0;
      padding-bottom: 0;
      scroll-snap-align: start;
      scroll-snap-stop: always;
      
      & > .container {
        height: 100vh;
      }`
      : ""}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    ${props =>
      props.scrollSnap
        ? `
        padding-top: 5rem;
        padding-bottom: 5rem;
        
        & > .container {
          height: unset;
        }`
        : ""}
  }

  .title {
    font-size: ${({ theme }) => theme.typography.pxToRem(56)};
    margin: 0 0 20px;
    line-height: 1.1em;
    font-weight: 400;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(34)};
    }
  }

  .content p,
  .content li {
    margin-top: 0;
    font-weight: strong;
    font-family: ${({ theme }) => theme.fonts.tertiary};
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  }

  .buttons .custom-button:not(:last-child) {
    margin-right: 1rem;
  }
`
