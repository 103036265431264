import React from "react"
import * as S from "./awards-header.styles.jsx"
import parse from "html-react-parser"
import { useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import GradientRoundedButton from "../../components/gradient-rounded-button/gradient-rounded-button.component.jsx"

const AwardsHeader = data => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <S.Wrapper
      scrollSnap={data.scrollSnap}
      className={
        (data.applyMask ? "masked" : "") +
        " " +
        (data.textColor === "blue" ? "text-blue" : "")
      }
    >
      {data.desktop.videoBackground ? (
        <S.Video
          src={
            isMobile
              ? data.mobile.videoBackground.publicUrl
              : data.desktop.videoBackground.publicUrl
          }
          poster={
            isMobile
              ? data.mobile.backgroundImage?.publicUrl
              : data.desktop.backgroundImage?.publicUrl
          }
          playsInline
          muted
          autoPlay
          loop
        ></S.Video>
      ) : (
        <S.Image
          className="image"
          img={
            isMobile
              ? data.mobile.backgroundImage
              : data.desktop.backgroundImage
          }
        />
      )}
      <S.FloatingContainer>
        <S.ContentArea>
          <S.AwardLogo className="image" img={data.awardLogo} />
          <h1>{parse(data.title)}</h1>
          {data.subtitle && <S.Subtitle>{parse(data.subtitle)}</S.Subtitle>}
          {data.button && (
            <GradientRoundedButton
              href={data.button.url}
              target={data.button.target}
            >
              {parse(data.button.title)}
            </GradientRoundedButton>
          )}
        </S.ContentArea>
      </S.FloatingContainer>
    </S.Wrapper>
  )
}

export default AwardsHeader
