import React from "react"
import parse from "html-react-parser"
import * as S from "./featured-media-section.styles.jsx"
import GradientRoundedButton from "../../components/gradient-rounded-button/gradient-rounded-button.component.jsx"
import MaskedMedia from "../../components/masked-media/masked-media.component.jsx"

const FeaturedMediaSection = data => {
  return (
    <S.Wrapper
      contained
      scrollSnap={data.scrollSnap}
      containerClassName={"container " + (data.flip ? "flip" : "")}
    >
      <div className="copy">
        {data.title && <h3 className="title">{parse(data.title)}</h3>}
        {data.content && <div className="content">{parse(data.content)}</div>}
        {data.buttons && data.buttons.length && (
          <div className="buttons">
            {data.buttons.map((button, index) => (
              <GradientRoundedButton
                key={index}
                target={button.button.target}
                href={button.button.url}
              >
                {button.button.title}
              </GradientRoundedButton>
            ))}
          </div>
        )}
      </div>

      <MaskedMedia media={data.media} />
    </S.Wrapper>
  )
}
export default FeaturedMediaSection
