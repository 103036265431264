import styled from "styled-components"
import Container from "@material-ui/core/Container"
import CustomImage from "../../components/custom-image/custom-image.component"
import { keyframes } from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  height: 650px;

  &.masked {
    margin-top: -10px;
    clip-path: url("#header-mask");
    ${({ theme }) => theme.breakpoints.down("xs")} {
      clip-path: url(#header-mask-mobile);
    }
  }

  ${props =>
    props.scrollSnap
      ? `scroll-snap-align: start;
      scroll-snap-stop: always;`
      : ""}

  &.text-blue {
    h1,
    p {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    height: 100vh;
  }
`

export const FloatingContainer = styled(Container)`
  position: absolute;
  z-index: 10;
  top: var(--header-height);
  left: 50%;
  transform: translateX(-50%);
  height: calc(80% - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  // padding-left: 169px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    transform: none;
    left: 0;
    width: 100%;
    justify-content: flex-start;
  }
`

export const Video = styled.video`
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`
export const Image = styled(CustomImage)`
  min-width: 100%;
  height: 650px;
  background-color: #121212;

  .gatsby-image-wrapper img {
    object-position: 63%;
    max-width: 2000px;
    margin: 0 auto;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    height: 100vh;
    .gatsby-image-wrapper {
      background-color: #000;
    }
    .gatsby-image-wrapper img {
      object-fit: contain;
      object-position: bottom;
    }
  }
`

export const AwardLogo = styled(CustomImage)`
  width: 200px;
  margin-bottom: 10px;
  margin-top: 40px;

  .gatsby-image-wrapper div {
    background-color: transparent !important;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-top: 0px;
    width: 150px;
  }
`

export const ContentArea = styled.div`
  max-width: 500px;
  color: #fff;

  h1 {
    font-weight: 400;
    font-size: clamp(2.5rem, 7vw, 4rem);
    line-height: initial;
    small {
      font-size: 0.6em;
      text-tranform: uppercase;
    }

    ${({ theme }) => theme.breakpoints.down("xs")} {
      font-size: 3rem;
      line-height: 1em;
    }
  }

  span {
    display: block;
  }

  sup {
    font-size: 0.5em;
    top: -0.1em;
  }
`
export const Subtitle = styled.p`
  margin-top: 10px;
  font-weight: 300;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.tertiary};
  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 1.5rem;
  }
`

const bounceAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-35px); }
  100% { transform: translateY(0); } 
`

export const Arrow = styled.div`
  position: absolute;
  left: calc(50% - 15px);
  bottom: 10vh;
  z-index: 8;
  max-width: 50px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${bounceAnimation};
  animation-timing-function: linear;
  width: 30px;

  svg {
    width: 30px;
    path {
      fill: #fff;
    }
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    // display: none;
  }
`
