import React from "react"
import CustomImage from "../../components/custom-image/custom-image.component"
import * as S from "./masked-media.styles"

const MaskedMedia = props => {
  const { image, video, mask, maskAspectRatio } = props.media

  //Determine what to display within the MediaContainer section
  if (image && !video) {
    //Only an image here
    var content = <CustomImage className="image" img={image} />
  } else if (video) {
    //Show a video, with the image used as a poster, if we have it
    var content = (
      <video
        className="video"
        src={video.publicUrl}
        poster={image.publicUrl ?? ""}
        playsInline
        muted
        autoPlay
        loop
      ></video>
    )
  } else {
    //Don't show anything at all
    var content = ""
  }

  return (
    <S.MediaContainer
      className={`media ${props.className ?? ""} ${
        mask !== "none" ? "masked" : ""
      } ${video ? "video" : ""}`}
      maskId={mask}
      aspectRatio={maskAspectRatio ?? "auto"}
    >
      {content}
    </S.MediaContainer>
  )
}

export default MaskedMedia
