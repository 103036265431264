import styled from "styled-components"

export const MediaContainer = styled.div`
    background-color: #fff;

    &.masked {
        clip-path: url(#${props => props.maskId});
    }

    &.video {
        background-color: transparent;
    }

    & > .image {
        aspect-ratio: ${props => props.aspectRatio};
    }

    & > .video {
        width: 100%;
    }
`